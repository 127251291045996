@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Regular'), url('../fonts/common/ProximaNovaRegular.woff2') format('woff2'), url('../fonts/common/ProximaNovaRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova SemiBold'), url('../fonts/common/ProximaNovaSemibold.woff2') format('woff2'), url('../fonts/common/ProximaNovaSemibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: local('Proxima Nova Bold'), url('../fonts/common/ProximaNovaBold.woff2') format('woff2'), url('../fonts/common/ProximaNovaBold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

