@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@import '../assets/fonts/ios/ios.fonts.css';
@import '../assets//fonts/font-common.css';
* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  font-family: 'Proxima Nova', sans-serif;
  font-weight: 400;
}

.android body {
  font-family: 'Proxima Nova', 'Roboto', sans-serif;
}

.ios body {
  font-family: 'SF Pro', sans-serif;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}
a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;
}
button {
  padding: 0;
  border: none;
  cursor: pointer;
  background-color: transparent;
  line-height: inherit;
  transition: all 0.2s ease;
}

svg {
  transition: all 0.2s ease;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  padding: 0;
}
[type='search'] {
  padding: 0;
}
input:focus {
  outline: none;
}

[contenteditable='true'] {
  -webkit-user-select: text;
  -moz-user-select: text;
  user-select: text;
  outline: none;
  cursor: text;
  white-space: pre-wrap;
  caret-color: #000;
  color: #000;
  background-color: transparent;
}
[dir='auto'] {
  unicode-bidi: plaintext;
}
.App {
  max-width: 100vw;
  transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1) 0s, border-radius;
}
.App.open {
  transform-origin: center top;
  transition: transform 0.5s cubic-bezier(0.32, 0.72, 0, 1) 0s, border-radius;
  border-radius: 8px;
  overflow: hidden;
  transform: scale(0.9861111111111112) translate3d(0, calc(0px + 14px), 0);
}
.flex {
  display: flex;
  align-items: center;
}

.title {
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
}
.subtitle {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
}

.ReactModal__Overlay {
  position: fixed;
  inset: 0;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
  background-color: rgba(16, 18, 32, 0.4) !important;
}
.ReactModal__Overlay.danger-overlay {
  background-color: rgba(41, 47, 81, 0.3) !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Overlay.danger-overlay::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background-color: rgba(255, 69, 58, 0.03);
}
.ReactModal__Overlay--after-open {
  opacity: 1;
  overflow-y: auto;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}
.ReactModal__Content {
  min-width: 732px;
  overflow-y: auto;
  width: auto;
  position: absolute;
  padding: 52px 0 !important;
  border: none !important;
  border-radius: 20px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff !important;
}
.ReactModal__Content > div.modal {
  top: 55px;
  right: 129px;
  z-index: 20;
}
.ReactModal__Content::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1600px) {
  .ReactModal__Content {
    padding: 30px 0 !important;
  }
  .ReactModal__Content > div.modal {
    top: 33px;
    right: 129px;
    z-index: 20;
  }
}
.ReactModal__Content.ultraBig {
  max-height: 95vh;
  height: 875px;
  min-width: 960px;
  left: 55%;
  padding: 60px 125px !important;
}
.ReactModal__Content.small > div.modal {
  top: 33px;
  right: 30px;
  z-index: 20;
}
.ReactModal__Content.small {
  min-width: 540px;
  max-height: 90vh;
  overflow-y: auto;
  width: auto;
  position: absolute;
  padding: 30px 0 !important;
  border: none !important;
  border-radius: 15px !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff !important;
}
.ReactModal__Content.danger {
  max-width: 540px;
  min-width: 540px;
  width: 100%;
  padding: 31px 30px !important;
  border-color: #ff453a !important;
  /*inset: auto !important;*/
}

.ReactModal__Content.ticket {
  border-color: #ff453a !important;
  /* inset: 30vh 15vw auto 15vw !important; */
  /*inset: auto !important;*/
  box-shadow: 0px 4px 64px 0px rgba(41, 47, 81, 0.14);
  max-height: 501px;
  overflow: scroll !important;
  width: 100%;
}
.ReactModal__Content.danger > button {
  display: none;
}

.ReactModal__Content.change-tarif-modal {
  padding: 40px !important;
  max-width: 610px;
  top: 51px;
  right: 0;
  left: auto;
  transform: none;
  height: 100%;
  border-radius: 8px 0px 0px 8px;
  box-shadow: 0px 6.4px 28px 0px rgba(0, 0, 0, 0.12), 0px 1.2px 18px 0px rgba(0, 0, 0, 0.08);
}

.ReactModal__Content.change-tarif-modal .close-btn {
  padding: 3px;
  top: 40px;
  right: 40px;
  display: flex;
}

.ReactModal__Content.update-employee-schedule-modal {
  padding: 0 0 32px 0 !important;
  top: 51px;
  right: 0;
  left: auto;
  transform: none;
  height: calc(100% - 62px);
  border-radius: 0px !important;
  box-shadow: 0px 6.4px 28px 0px rgba(0, 0, 0, 0.12), 0px 1.2px 18px 0px rgba(0, 0, 0, 0.08);
  min-width: 1300px;
  overflow: visible;
}

.ReactModal__Content.update-employee-schedule-modal .close-btn {
  padding: 5px;
  top: 20px;
  left: -14px;
  display: flex;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: fit-content;
  background-color: #fff;
}
.ReactModal__Overlay.time-table {
  top: 144px;
  right: 0;
  bottom: 0;
  left: auto;
  background-color: transparent !important;
  overflow: visible;
}
.ReactModal__Content.time-table-modal {
  padding: 32px 40px !important;
  top: 0;
  right: 0;
  left: auto;
  transform: none;
  height: 100%;
  border-radius: 20px 0px 0px 0px !important;
  box-shadow: 0px 6.4px 28px 0px rgba(0, 0, 0, 0.12), 0px 1.2px 18px 0px rgba(0, 0, 0, 0.08);
  min-width: 388px;
  overflow: visible;
}
.ReactModal__Content.time-table-modal > div.modal {
  top: 12px;
  right: 40px;
}
.ReactModal__Content.time-table-modal .close-btn {
  padding: 5px;
  top: 10px;
  left: -14px;
  display: flex;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 50%;
  width: fit-content;
  background-color: #fff;
}
@media (max-width: 1500px) {
  .ReactModal__Content {
    max-height: 95%;
    overflow-y: auto;
  }
}

@media (max-width: 1280px) {
  .title {
    font-size: 24px;
  }
}

.container {
  position: relative;
  padding: 0 20px;
}
@media (max-width: 1500px) {
  .container {
    padding: 0 10px;
  }
}

.page-wrapper {
  animation: fade 0.3s ease;
  height: 100%;
  /* padding-top: 20px; */
}
.page-wrapper.success-sale {
  display: flex;
  align-items: center;
}
@media (max-width: 767px) {
  .page-wrapper {
    /* padding-top: 12px; */
    padding-bottom: 30px;
    /*animation: none;*/
  }
  .container {
    padding: 0;
    height: 100%;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.slick-list,
.slick-slider,
.slick-track {
  padding: 0.4rem 0 0.7rem 0 !important;
}

.center .slick-center .project {
  transform: scale(1.3);
  z-index: 10000;
  transition: all 400ms ease-in-out;
  pointer-events: all;
  opacity: 1;
}

.swipeable-container {
  transition: transform 0.2s ease-in-out;
  height: 100%;
}

.swipeable-container.swipe-right {
  animation: changeDay 0.3s ease-in-out;
}

.swipeable-container.swipe-left {
  animation: changeDay2 0.3s ease-in-out;
}

@keyframes changeDay {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(20px);
  }

  50% {
    opacity: 0;
    visibility: hidden;
  }
  75% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes changeDay2 {
  0% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-20px);
  }

  50% {
    opacity: 0;
    visibility: hidden;
  }
  75% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0px);
  }
}
.toast-container {
  width: fit-content;
  position: relative;
  z-index: 99999;
}
.toast-container > div:before {
  content: '';
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
}
.notify {
  background: #fff;
}
.notify .notify-icon {
  width: 16px;
  min-width: 16px;
  height: 16px;
}
.notify .notify-icon svg {
  width: 100%;
  height: 100%;
}
.notify .notify-icon:before {
  content: '';
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background: #4ac99b1f;
}
.notify .notify-icon.error:before {
  background: #f457251f;
}
